// extracted by mini-css-extract-plugin
export const highlights = "highlights-module--highlights--hcwVd";
export const light = "highlights-module--light--3feaR";
export const highlight = "highlights-module--highlight--1vipx";
export const noImage = "highlights-module--noImage--3PFPR";
export const highlightContentCol = "highlights-module--highlightContentCol--1yisX";
export const image = "highlights-module--image--OoJPW";
export const highlightImageCol = "highlights-module--highlightImageCol--wJ3UW";
export const highlightsButton = "highlights-module--highlightsButton--2wChW";
export const row = "highlights-module--row--5JQ-b";
export const highlightsButtonLeft = "highlights-module--highlightsButtonLeft--1jbTo";
export const highlightsButtonRight = "highlights-module--highlightsButtonRight--18Cev";
export const carouselDotGroupContainer = "highlights-module--carouselDotGroupContainer--F4lxe";
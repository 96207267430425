import classNames from 'classnames'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Highlight, HighlightDocument } from 'src/types'
import ArrowLeftLight from '../assets/arrow-left-circle-light.svg'
import ArrowLeft from '../assets/arrow-left-circle.svg'
import ArrowRightLight from '../assets/arrow-right-circle-light.svg'
import ArrowRight from '../assets/arrow-right-circle.svg'
import * as carouselStyles from './carousel-styles.module.scss'
import FeatureCard from './feature-card'
import * as styles from './highlights.module.scss'
import ResponsiveImage from './responsive-image'

const DEFAULT_LINK_TEXT: Record<string, string> = {
  company: 'View Investment Details',
}

const HighlightItem = ({ highlight, type }: { highlight: Highlight; type: string }) => {
  const image = highlight.image?.thumbnails?.large

  return (
    <div
      className={classNames(styles.highlight, {
        [styles.noImage]: !image,
      })}
    >
      <Row className={classNames(styles.row, 'flex-column-reverse flex-xl-row')}>
        <Col xs={12} xl={!image ? 12 : 4} className={styles.highlightContentCol}>
          <FeatureCard
            title={highlight.title.text}
            heading={highlight.header.text}
            description={highlight.description.raw}
            linkUrl={highlight.link.url}
            linkName={highlight.link_name.text || DEFAULT_LINK_TEXT[type]}
            breakEarly={true}
            dark={true}
          />
        </Col>
        {image && (
          <Col xs={12} xl={8} className={styles.highlightImageCol}>
            <ResponsiveImage image={image} className={styles.image} />
          </Col>
        )}
      </Row>
    </div>
  )
}

const Highlights = ({
  highlights,
  type,
  light,
  hideTitle,
}: {
  highlights: HighlightDocument[]
  type: string
  light?: boolean
  hideTitle?: boolean
}) => {
  return (
    <div className={classNames(styles.highlights, { [styles.light]: light })}>
      {!hideTitle && <div className="h1 mb-4">News & Highlights</div>}
      <CarouselProvider
        isIntrinsicHeight={true}
        naturalSlideWidth={994}
        naturalSlideHeight={400}
        infinite={true}
        totalSlides={highlights.length}
      >
        <Slider>
          {highlights.map((highlight, index) => (
            <Slide index={index} key={index}>
              <HighlightItem highlight={highlight.data} type={type} />
            </Slide>
          ))}
        </Slider>
        {highlights.length > 1 && (
          <>
            <ButtonBack
              className={classNames(styles.highlightsButton, styles.highlightsButtonLeft)}
            >
              {light ? <ArrowLeftLight /> : <ArrowLeft />}
            </ButtonBack>
            <ButtonNext
              className={classNames(styles.highlightsButton, styles.highlightsButtonRight)}
            >
              {light ? <ArrowRightLight /> : <ArrowRight />}
            </ButtonNext>
            <div className={styles.carouselDotGroupContainer}>
              <DotGroup className={carouselStyles.carouselDotGroup} />
            </div>
          </>
        )}
      </CarouselProvider>
    </div>
  )
}

export default Highlights

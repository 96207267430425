import classNames from 'classnames'
import { Link } from 'gatsby'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import * as styles from './feature-card.module.scss'

type Props = {
  title?: string
  heading?: string
  description?: RichTextBlock[]
  cardPath?: string
  linkUrl?: string
  linkName?: string
  dark?: boolean
  breakEarly?: boolean
}

const Content = ({ title, heading, description, linkUrl, linkName, dark }: Props) => {
  return (
    <>
      {title && <div className={styles.title}>{title}</div>}
      <div className="mt-auto">
        {heading && <h4 className={styles.heading}>{heading}</h4>}
        {description && (
          <div className={styles.description}>
            <RichText render={description} />
          </div>
        )}

        {linkUrl ? (
          <a
            href={linkUrl}
            target="blank"
            className={classNames('link-underline', dark && 'link-light')}
          >
            {linkName}
          </a>
        ) : (
          <span className="link-underline">{linkName}</span>
        )}
      </div>
    </>
  )
}

const FeatureCard = (props: Props) => {
  const classes = classNames(styles.featureCard, {
    [styles.dark]: props.dark,
    [styles.breakEarly]: props.breakEarly,
  })

  if (props.cardPath) {
    return (
      <Link to={props.cardPath} className={classes}>
        <Content {...props} />
      </Link>
    )
  }

  return (
    <div className={classes}>
      <Content {...props} />
    </div>
  )
}

export default FeatureCard
